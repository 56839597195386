/* global google */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * prevent background scroll when mobile menu is open
 */
let floatingPageScroll = 0;
function toggleStatic() {
  let $body = $('body'),
    $menu = $('.menu-icon');
  if ($menu.hasClass('is-active')) {
    floatingPageScroll = $(window).scrollTop();
    setTimeout(function () {
      $body.addClass('st');
    }, 200);
  } else {
    $('body').removeClass('st');
    $('html, body').animate(
      {
        scrollTop: floatingPageScroll,
      },
      0
    );
  }
}

function getParameter(paramName) {
  let pageURL = window.location.search.substring(1),
    urlVariables = pageURL.split('&'),
    parameterName,
    i;

  for (i = 0; i < urlVariables.length; i++) {
    parameterName = urlVariables[i].split('=');

    if (parameterName[0] === paramName) {
      return parameterName[1] === undefined
        ? true
        : decodeURIComponent(parameterName[1]);
    }
  }
  return false;
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  //check if we need to scroll page
  let staffId = parseInt(getParameter('staff'));

  if (staffId) {
    let target = $(`#staff-${staffId}`);

    if (target.length) {
      $('html, body').animate({ scrollTop: target.offset().top }, 500);
    }
  }
  /**
   * prevent background scroll when mobile menu is open
   */
  $(document).on('click', '.menu-icon', function () {
    if ($(window).width() < 1025) toggleStatic();
  });

  /**
   * Sliders
   */
  // let $tourismSlider = $('.tourism-slider__big');
  //
  // $tourismSlider.on('afterChange', function (event, slick, currentSlide) {
  //   let $currentLinkActive = $('.tourism-link.is-active');
  //
  //   if ($currentLinkActive.data('slide') !== currentSlide) {
  //     $currentLinkActive.removeClass('is-active');
  //     $('.slide-' + currentSlide).addClass('is-active');
  //   }
  // });
  // $tourismSlider.slick({
  //   centerMode: true,
  //   centerPadding: '0px',
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: false,
  //   focusOnSelect: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         fade: true,
  //         adaptiveHeight: true,
  //       },
  //     },
  //   ],
  // });

  $('.tourism-tab-slider').slick({
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          fade: true,
          adaptiveHeight: true,
        },
      },
    ],
  });

  $('#tourism-tabs').on('change.zf.tabs', function () {
    $('.tabs-content .tabs-panel.is-active')
      .find('.tourism-tab-slider')
      .slick('reinit');
  });

  $('.team-slider').slick({
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          fade: true,
        },
      },
    ],
  });

  $('.testimonials-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    prevArrow: $('.prevArrow'),
    nextArrow: $('.nextArrow'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '22.2%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0',
          adaptiveHeight: true,
        },
      },
    ],
  });

  $('.featured-slider').slick({
    cssEase: 'ease',
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    prevArrow: $('.prevArrow'),
    nextArrow: $('.nextArrow'),
  });

  $('.flexible-slider').slick({
    cssEase: 'ease',
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    once: true,
    threshold: 0.05,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);

            if ($this.find('.dynamic-number')) {
              let $element = $this.find('.dynamic-number');
              $element.prop('Counter', 0).animate(
                {
                  Counter: parseInt($element.text()),
                },
                {
                  duration: 4000,
                  easing: 'swing',
                  step: function (now) {
                    $element.text(Math.ceil(now));
                  },
                }
              );
            }

            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
      // if ($(element).find('.stat-one')) {
      //   let $c = $(element).find('.dynamic-number');
      //   $c.prop('Counter', 0).animate(
      //     {
      //       Counter: parseInt($(this).text()),
      //     },
      //     {
      //       duration: 4000,
      //       easing: 'swing',
      //       step: function (now) {
      //         $(element).find('.dynamic-number').text(Math.ceil(now));
      //       },
      //     }
      //   );
      // }
    },
  });

  $(document).on('click', '.tourism-link', function () {
    let $this = $(this);

    if (!$this.hasClass('is-active')) {
      $('.tourism-link.is-active').removeClass('is-active');
      $this.addClass('is-active');
      $('.tourism-slider__big').slick(
        'slickGoTo',
        parseInt($this.data('slide'))
      );
    }
  });

  $(document).on('click', '.show-bio', function () {
    $(this).parents('.person-one').find('.bio').addClass('active');
  });

  $(document).on('click', '.close', function () {
    $(this).parents('.person-one').find('.bio').removeClass('active');
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  $(document).on('click', '.header-menu li a', function () {
    if ($(window).width() < 1025) {
      if (!$(this).attr('href')) {
        $(this).next('.submenu-toggle').click();
      } else {
        $('.menu-icon').click();
      }
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.header').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.header').toggleClass('is-active');
      if ($(window).width() < 1025) toggleStatic();
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // ACF Google Map JS code

  /*
   *  This function will render a Google Map onto the selected jQuery element
   */

  function render_map($el) {
    // var
    let $markers = $el.find('.marker'),
      styles = [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e9e9e9',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dedede',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36,
            },
            {
              color: '#333333',
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f2f2f2',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ];

    // vars
    var args = {
      zoom: 13,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      //disableDefaultUI: true,
      styles: styles,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;

  function add_marker($marker, map) {
    // var
    let latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    const markerObject = {
      url: $marker.data('marker-icon'),
      size: new google.maps.Size(96, 96),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(48, 48),
    };

    // create marker
    let marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: markerObject, //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */

  $('.acf-map').each(function () {
    render_map($(this));
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();

  if (!$('.menu-icon').hasClass('is-active'))
    $('.header').removeClass('is-active');
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
